import React, { useEffect, useState } from 'react'
import { GetMachineData } from '../../Service/MachineApi'
import { useNavigate } from 'react-router-dom'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import './Machine.css'
import Header from '../Common/Header/Header'

export default function Building() {
    const navigate = useNavigate()

    const [machinedata, setMachinedata] = useState([])

    const gettingBuildingData = async () => {
        let res = await GetMachineData()
        if (res.error != null) {
            toast.error(res.error)
        } else {
            setMachinedata(res.data)
        }
    }
    useEffect(() => {
        gettingBuildingData()
    }, [])


    return (
        <>
            <div className="machineMainContainer">
                <Header head={"MRS9000 GAS FLOW MONITORING WITH REPORTING"} />
                <div className="machineCard">
                    {
                        machinedata.map((bdata, i) => {
                            // const meterRead = bdata?.meterReadings
                            

                            return (
                                <div key={i}
                                    onClick={() => {
                                        navigate(`/dashboard/Dep?MQTT_ID=${bdata.MQTT_ID}`, { state: { roomName: bdata?.machineName, range:bdata?.range} })
                                    }}
                                    // className="machineName">{bdata?.machineName} <br />{meterRead[meterRead.length-1]?.temp}°C <br />{meterRead[meterRead.length-1]?.humidity}% <br />{meterRead[meterRead.length-1]?.time} |  {meterRead[meterRead.length-1]?.date} </div>
                                    className="machinemain">
                                        <div className="machineName">{bdata?.machineName}</div> 
                                    <div className="deviceid">{bdata?.Device_ID}</div> 
                                    </div>
                            )
                        })
                    }
                </div>
            </div>
            <ToastContainer/>
        </>
    )
}
